import "../App.css"

function BottomNav() {
    return (
      <div className="page bottom-nav">
        <h1 className="bottom-title gradient"><a href="https://descope.com" target="_blank">Powered by Descope</a></h1>
      </div>
    )
}


export default BottomNav
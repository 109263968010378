import "../App.css"

function Introduction({ title, subtitle, desc }) {
    return (
      <div className="page">
        {title !== "_" && <h1 className={"title gradient"}>{title} </h1> }
        {subtitle !== "_" && <h5 className={"title gradient"}>{subtitle} </h5> }
        {desc !== "_" && <p className="tagline">{desc}</p> }
      </div>
    )
}

export default Introduction

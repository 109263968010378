import { AuthProvider } from '@descope/react-sdk'
import AuthFlow from './components/AuthFlow';
import Navbar from './components/Navbar';
import Introduction from './components/Introduction';
import BottomNav from './components/BottomNav';

import './App.css';


const setURL = (theme, project, flow) => {
  var url = new URL(window.location.href);
  var search_params = url.searchParams;

  search_params = checkSetParam('theme', search_params, theme)
  search_params = checkSetParam('project', search_params, project)
  search_params = checkSetParam('flow', search_params, flow)

  url.search = search_params.toString();
  const new_url = url.toString();
  window.location.replace(new_url) 
}


const checkSetParam = (param, search_params, val) => {
  let new_search = search_params
  if (val) { new_search.set(param, val) }
  return new_search
}


function App() {
  
  const theme = "dark"
  const project = process.env.REACT_APP_DESCOPE_PROJECT_ID
  const queryParameters = new URLSearchParams(window.location.search)
  const eventCode = queryParameters.get("eventCode") || ""

  const title = process.env[`REACT_APP_${eventCode.toUpperCase()}_TITLE`] || "Descope Events";
  const subtitle = process.env[`REACT_APP_${eventCode.toUpperCase()}_SUBTITLE`] || "Event Check-In";
  const desc = process.env[`REACT_APP_${eventCode.toUpperCase()}_DESC`] || "Easy way to check into events, and have your name tag printed.";
  const flow = process.env[`REACT_APP_${eventCode.toUpperCase()}_FLOW`] || "checkin";

  return (
    <>
        <>
          <Navbar 
            theme={theme} 
            setURL={setURL} 
          />
          <Introduction theme={theme} title={title} subtitle={subtitle} desc={desc}/>

          <AuthProvider projectId={project}>
            <AuthFlow 
              defaultFlow={flow} 
              theme={theme} 
              borderRounded = {project}
            />
          </AuthProvider>
          <BottomNav />
        </>
    </>
  )
}


export default App;

import Logo from "../assets/logo.png"
import "../App.css"

function Navbar() {
    return (
        <nav className="navbar">
            <a className="logo-link" target="_blank" rel="noreferrer" href="https://www.descope.com/">
                <img className="logo" src={Logo} alt="" />
            </a>
        </nav>
    )
}

export default Navbar















import { Descope } from '@descope/react-sdk'
import '../App.css'

function AuthFlow({ defaultFlow, theme, borderRounded }) {
  return (
    <div className='page authflow'>
      {(
        <div className={borderRounded ? 'page flow-shown': "page flow-shown rounded-top"}>
          <Descope
            flowId={defaultFlow}
            onSuccess={(e) => console.log(e)}
            theme={theme}
          />
        </div>
      )}
    </div>
  )
}

export default AuthFlow
